<template>
    <div class="edit-view">
        <page-title>
            <el-button @click="$router.go(-1)" :disabled="saving">取消</el-button>
            <el-button @click="onDelete" :disabled="saving" type="danger" v-if="formData.id"> 删除 </el-button>
            <el-button @click="onSave" :loading="saving" type="primary">保存</el-button>
        </page-title>
        <div class="edit-view__content-wrapper">
            <div class="edit-view__content-section">
                <el-form
                    :model="formData"
                    :rules="rules"
                    ref="form"
                    label-width="61px"
                    label-position="right"
                    size="small"
                    style="max-width: 500px"
                >
                    <el-form-item prop="name" label="name">
                        <el-input v-model="formData.name"></el-input>
                    </el-form-item>
                    <el-form-item class="form-submit">
                        <el-button @click="onSave" :loading="saving" type="primary"> 保存 </el-button>
                        <el-button @click="onDelete" :disabled="saving" type="danger" v-if="formData.id">
                            删除
                        </el-button>
                        <el-button @click="$router.go(-1)" :disabled="saving">取消</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'TestClassEdit',
    created() {
        if (this.$route.query.id) {
            this.$http
                .get('testClass/get/' + this.$route.query.id)
                .then(res => {
                    this.formData = res;
                })
                .catch(e => {
                    console.log(e);
                    this.$message.error(e.error);
                });
        }
    },
    data() {
        return {
            saving: false,
            formData: {},
            rules: {}
        };
    },
    methods: {
        onSave() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.submit();
                } else {
                    return false;
                }
            });
        },
        submit() {
            let data = { ...this.formData };

            this.saving = true;
            this.$http
                .post('/testClass/save', data, { body: 'json' })
                .then(res => {
                    this.saving = false;
                    this.$message.success('成功');
                    this.$router.go(-1);
                })
                .catch(e => {
                    console.log(e);
                    this.saving = false;
                    this.$message.error(e.error);
                });
        },
        onDelete() {
            this.$alert('删除将无法恢复，确认要删除么？', '警告', { type: 'error' })
                .then(() => {
                    return this.$http.post(`/testClass/del/${this.formData.id}`);
                })
                .then(() => {
                    this.$message.success('删除成功');
                    this.$router.go(-1);
                })
                .catch(e => {
                    if (e !== 'cancel') {
                        console.log(e);
                        this.$message.error((e || {}).error || '删除失败');
                    }
                });
        }
    }
};
</script>
<style lang="less" scoped></style>
